import React from "react"
import activityone from "../../images/svg/servicesgraphic.svg"
import activitytwo from "../../images/svg/servicesphoto.svg"
import activitythree from "../../images/svg/serviceswebsite.svg"
import activityfour from "../../images/svg/servicesdigital.svg"
import activityfive from "../../images/svg/servicesadvertsing.svg"
import activitysix from "../../images/svg/servicesevents.svg"
import activityseven from "../../images/svg/printing-services.svg"
import { Link } from "gatsby"

const websiteactivity = () => {
  return (
    <section id="activity" className="section-bg">
      <div className="container-fluid " data-aos="fade-up">
        <div className="row justify-content-center ">
          <div
            className="col-lg-12 service-activities px-0"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="box  activityone ">
              <div className="icon">
                <img
                  src={activityone}
                  alt="activityone"
                  className="portfolio-activity "
                ></img>
              </div>
              <h4 className="title text-white">
                <Link to="/services/graphic-design" className="stretched-link">
                  Graphic Design
                </Link>
              </h4>
              <p className="description">
                We strive to differentiate your brand from the competition by
                understanding your unique requirements.
              </p>
            </div>
          </div>
          <div
            className="col-lg-12 px-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="box activitytwo">
              <div className="icon">
                <img
                  src={activitytwo}
                  alt="activitytwo"
                  className="portfolio-activities"
                ></img>
              </div>
              <h4 className="title">
                <Link
                  to="/services/photography-videography"
                  className="stretched-link"
                >
                  Photography &amp; Videography
                </Link>
              </h4>
              <p className="description">
                We strive to differentiate your brand from the competition by
                understanding your unique requirements.
              </p>
            </div>
          </div>

          <div
            className="col-lg-12 px-0"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="box activitythree">
              <div className="icon">
                <img
                  src={activitythree}
                  alt="activitythree"
                  className="portfolio-activities"
                ></img>
              </div>
              <h4 className="title">
                <Link
                  to="/services/website-mobile-app-development"
                  className="stretched-link"
                >
                  Website &amp; Mobile Application Development
                </Link>
              </h4>
              <p className="description">
                We strive to differentiate your brand from the competition by
                understanding your unique requirements.
              </p>
            </div>
          </div>
          <div
            className="col-lg-12 px-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="box activityfour">
              <div className="icon">
                <img
                  src={activityfour}
                  alt="activityfour"
                  className="portfolio-activit"
                ></img>
              </div>
              <h4 className="title">
                <Link
                  to="/services/digital-marketing"
                  className="stretched-link"
                >
                  Digital Marketing
                </Link>
              </h4>
              <p className="description">
                We strive to differentiate your brand from the competition by
                understanding your unique requirements.
              </p>
            </div>
          </div>

          <div
            className="col-lg-12 px-0"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="box activityfive">
              <div className="icon">
                <img
                  src={activityfive}
                  alt="activityfive"
                  className="portfolio-activit"
                ></img>
              </div>
              <h4 className="title">
                <Link
                  to="/services/advertising-promotional"
                  className="stretched-link"
                >
                  Advertishing &amp; Promotional MIX
                </Link>
              </h4>
              <p className="description">
                We strive to differentiate your brand from the competition by
                understanding your unique requirements.
              </p>
            </div>
          </div>
          <div
            className="col-lg-12 px-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="box activitysix">
              <div className="icon">
                <img
                  src={activitysix}
                  alt="activitysix"
                  className="portfolio-act"
                ></img>
              </div>
              <h4 className="title">
                <Link
                  to="/services/events-exhibitions"
                  className="stretched-link"
                >
                  Events &amp; Exhibitions
                </Link>
              </h4>
              <p className="description">
                We strive to differentiate your brand from the competition by
                understanding your unique requirements.
              </p>
            </div>
          </div>
          <div
            className="col-lg-12 px-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="box activityseven">
              <div className="icon">
                <img src={activityseven} alt="activityseven"></img>
              </div>
              <h4 className="title">
                <Link
                  to="/services/printing-services"
                  className="stretched-link"
                >
                  Printing Services
                </Link>
              </h4>
              <p className="description">
                We strive to differentiate your brand from the competition by
                understanding your unique requirements.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default websiteactivity
