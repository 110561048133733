import React from "react"

const servicehome = () => {
  return (
    <>
      <section id="cta" className="cta">
        <div className="container">
          <div className="row" data-aos="zoom-in">
            <div className="col-lg-9  text-lg-left">
              <p className="client-text">
                Clients have recommended <br />
                our Branding Services
              </p>
            </div>
            <div className="col-lg-3 client-btn ">
              <a
                className="cta-btns  "
                href="../../../assets/brochures/massmediacreatives-brochure.pdf"
                target="_blank"
              >
                <button type="button" className="btn btn-outline-light">
                  Download MMC Casestudy
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default servicehome
