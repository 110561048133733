import React from "react"

const websitedigital = () => {
  return (
    <section>
      <div className="container py-4">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <p className="services-digital">
              Exclusive Services for the wholesome experiences of the Digital
              World
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default websitedigital
