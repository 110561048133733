import React from "react"

import Layout from "../components/layout"
import ServicesPage from "../components/services/index"
import Seo from "../components/seo"

const servicespage = () => (
  <Layout>
    <Seo
      title="Services - Mass Media Creatives"
      desc="Our multidisciplinary approach ensures success in your business endeavors. We believe in efficient and effective communication to connect you with your customers"
      keywords="Digital Printing Services in chennai, Graphic Design in chennai, Photography &amp; videography in chennai, Digital Marketing in chennai"
    />
    <ServicesPage />
  </Layout>
)

export default servicespage
