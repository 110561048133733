import React from "react"

import gallery from "../../images/showcase/Image 46.png"

const websitegallery = () => {
  return (
    <>
      <section>
        <div className="helping">
          <img src={gallery} alt="gallery" />

          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="headprd position-relative">
                  <h4 className="">
                    Our innovative <br /> Solutions
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default websitegallery
