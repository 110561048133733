import React from "react"

const servicesportfolio = () => {
  return (
    <section id="call-to-actions" className="wow fadeInUp">
      <div className="container py-4">
        <div className="row">
          <div className="services-caption text-center">
            <h4 className="heading fw-bold about-heading ">
              CREATIVE SHOWCASE
            </h4>
            <p className="started-textthree">
              SHOWCASING OUR PROJECTS THAT INSPIRE US TO DO MORE
            </p>
          </div>
          <div className="col-12 col-lg-12 portfolio-text">
            <p className="cta-text"> VIEW PORTFOLIO</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default servicesportfolio
