import React from "react"

const grow = () => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <h4 className="heading fw-bold about-heading">
              How we help you grow your brand
            </h4>
            <p className="story-text pt-4">
              Started with a vision of establishing businArtistic and engaging
              graphic designs with a touch of
              <br /> empathyess brands and driven with the thought of
              innovation.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default grow
