import React from "react"
import Slider from "./slider"
import Gallery from "./gallery"
import Services from "./services"
import Grow from "./grow"
import Activity from "./activity"
import Digital from "./digital"
import Portfolio from "./portfolio"
import Service from "../home/services"

const servicespage = () => {
  return (
    <>
      <Slider />
      <Services />
      <Gallery />
      <Grow />
      <Activity />
      <Digital />
      <Portfolio />
      <Service />
    </>
  )
}

export default servicespage
