import React from "react"

const servicesstyle = () => {
  return (
    <section>
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <h4 className="heading fw-bold about-heading">Services</h4>
            <p className="story-text">
              Our multidisciplinary approach ensures success in your business
              endeavors. We believe in efficient and effective communication to
              connect you with your customers. Along with communication, we
              implement a dynamic and unique strategy for digital marketing. We
              aim at creating a high-quality customer experience. Mass Media
              Creatives brands you and your business as an epitome of the
              digital world.
            </p>
            <p className="about-digital pt-4">
              Be on top of your game with impeccable digital branding solutions
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default servicesstyle
